<template>
  <div class="ModalMap" ref="ModalMap">
    <Chart
      @on-click="mapClick"
      v-loading="isMapLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(51, 51, 51, 0.8)"
      :option="mapOption"
      style="width: 100%; height: calc(100% - 15%)"
    ></Chart>
    <el-button
      class="back"
      type="primary"
      v-if="mapName !== 'china'"
      circle
      @click.native="backToUp"
    ></el-button>
    <!-- 中国石油 -->
    <div class="identification-box" v-if="!isZgsy">
      <div @click="handleNavi('/BigScreenDisplayEquipmentInfo')">设备信息</div>
      <div @click="handleNavi('/BigScreenDisplayCustomerInfo')">客户信息</div>
    </div>
    <div class="statistics-big-box">
      <div class="statistics-box statistics-box-bg1">
        <span>作业</span>
        <div style="width: 14px"></div>
        <span
          ><span
            class="statistics-box-num"
            style="color: rgba(98, 255, 168, 1)"
            >{{
              options.overview && options.overview.jobTotal
                ? options.overview.jobTotal
                : 0
            }}</span
          >
          台</span
        >
        <div style="width: 20px"></div>
        <span
          ><span
            class="statistics-box-num"
            style="color: rgba(98, 255, 168, 1)"
            >{{
              options.overview && options.overview.jobRate
                ? options.overview.jobRate
                : 0
            }}</span
          >%</span
        >
      </div>
      <div class="statistics-box statistics-box-bg2">
        <span>怠速</span>
        <div style="width: 14px"></div>
        <span
          ><span
            class="statistics-box-num"
            style="color: rgba(255, 253, 55, 1)"
            >{{
              options.overview && options.overview.idleTotal
                ? options.overview.idleTotal
                : 0
            }}</span
          >台</span
        >
        <div style="width: 20px"></div>
        <span
          ><span
            class="statistics-box-num"
            style="color: rgba(255, 253, 55, 1)"
            >{{
              options.overview && options.overview.idleRate
                ? options.overview.idleRate
                : 0
            }}</span
          >%</span
        >
      </div>
      <div class="statistics-box statistics-box-bg3">
        <span>离线</span>
        <div style="width: 14px"></div>
        <span
          ><span
            class="statistics-box-num"
            style="color: rgba(88, 182, 255, 1)"
            >{{
              options.overview && options.overview.offlineTotal
                ? options.overview.offlineTotal
                : 0
            }}</span
          >台</span
        >
        <div style="width: 20px"></div>
        <span
          ><span
            class="statistics-box-num"
            style="color: rgba(88, 182, 255, 1)"
            >{{
              options.overview && options.overview.offlineRate
                ? options.overview.offlineRate
                : 0
            }}</span
          >%</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Chart from "@/components/Chart/Charts";
import china from "@/components/Chart/china";
import CenterMap from "@/components/CenterMap";
import ModelBg2 from "../ModelBg2";
import PiChart from "../../PiChart.vue";

import { getVehicleStart } from "@/api/getData";
// import { getProductTypeIconName } from '@/utils/getProductTypeIconName';
import vehiclePicture from "@/utils/vehiclePicture";
import ElIcon from "../../../../components/Icon/ElIcon.vue";

import { getMapCarNumberInfo } from "@/api/Map/mapRequst.js";

import { getAreaVehicleCount } from "@/api/BigScreenDisplay";
import chinaLi from "@/components/CenterMap/mapJson/chinaMap.json";
import {
  filterAliMapData,
  filterMapData,
} from "@/components/CenterMap/mapUtils/index";
import { getLatLon } from "@/api/getData";
import { GetPercent } from "@/utils/index.js";

echarts.registerMap("china", china);
export default {
  components: {
    Chart,
    CenterMap,
    ElIcon,
    ModelBg2,
    PiChart,
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    productTypes: {
      // 产品种类
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      mapOption: {}, // 地图配置
      data: [],
      chartMap: "chartMap",
      productList: [], // 产品种类list
      carStatisticsList: [
        {
          label: "作业(台)",
          img: require("../../../../assets/images/bigScreen/carNum3.png"),
          value: 0,
          option: {},
        },
        {
          label: "通电(台)",
          img: require("../../../../assets/images/bigScreen/carNum2.png"),
          value: 0,
          option: {},
        },
        {
          label: "怠速(台)",
          img: require("../../../../assets/images/bigScreen/carNum4.png"),
          value: 0,
          option: {},
        },
        {
          label: "熄火(台)",
          img: require("../../../../assets/images/bigScreen/carNum5.png"),
          value: 0,
          option: {},
        },
        {
          label: "离线(台)",
          img: require("../../../../assets/images/bigScreen/carNum1.png"),
          value: 0,
          option: {},
        },
      ],
      isMapLoading: false,
      mapName: "china",
      mapAdcode: "china",
      mapHierarchyList: [],
    };
  },
  watch: {
    options: {
      handler(val) {
        this.isMapLoading = false;
        if (this.options.areaVehicleCount && this.mapName == "china") {
          this.data = this.options.areaVehicleCount.map((item) => ({
            name: item.provinceName,
            value: item.count,
          }));
          this.$nextTick(() => {
            this.mapOption = this.getMapOption(this.data);
            // this.getVehicleStartData()
          });
        }
      },
    },

    productTypes: {
      handler(value) {
        this.productList = value;
      },
    },
  },
  created() {
    this.imgUrl = new Image();
    this.imgUrl.src =
      "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/geo.png";
    this.imgUrl.style =
      "position: absolute;left: 10px;right: 0;top: 0;bottom: 0;";
    this.mapListData = chinaLi;
  },
  mounted() {
    this.$nextTick(() => {
      this.mapOption = this.getMapOption(this.data);
    });
  },
  methods: {
    //返回上一级
    async backToUp() {
      this.isMapLoading = true;
      //  this.$refs.mapInstance.goBack();
      //  let info= await getMapCarNumberInfo('中国')
      //  console.log(info.obj);
      // this.$emit('backToUp')
      this.mapBackToUp();
    },
    mapChange() {
      this.$refs.mapInstance.map.mapChange();
    },
    provinceShort(name = "") {
      if (name.startsWith("内蒙")) return "内蒙古";
      if (name.startsWith("黑龙")) return "黑龙江";
      return name.substring(0, 2);
    },
    getMapOption(data) {
      const mapFeatures = echarts.getMap(this.mapName).geoJson.features;
      const mapData = mapFeatures?.map((item) => {
        return {
          name: item.properties ? item.properties.name : "",
          value: item.properties ? item.properties.childNum : "",
          cp: item.properties
            ? item.properties.cp ||
              item.properties.centroid ||
              item.properties.center
            : "",
        };
      });
      const getSize = (res) => {
        const clientWidth = this.$refs["ModalMap"].offsetWidth;
        if (!clientWidth) return;
        const fontSize = 100 * (clientWidth / 1920);
        return res * fontSize;
      };
      // 地图标点图片
      let symbolImg = [
        "image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/num1.png",
        "image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/num2.png",
        "image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/num3.png",
        "image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/num4.png",
        "image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/num5.png",
      ];
      const convertData = (areaValue) => {
        const res = [];
        for (let i = 0; i < areaValue.length; i++) {
          mapData?.map((v) => {
            if (v.name.indexOf(this.provinceShort(areaValue[i].name)) > -1) {
              const value = v.cp.concat(areaValue[i].value);
              res.push({
                name: areaValue[i].name,
                value: value,
                label: {
                  show: true,
                  //position: 'top',
                  // padding: 4,
                  // backgroundColor: '#091B59',
                  // borderWidth: 3,
                  // borderColor: '#23E1DB',
                  // borderRadius: 800,
                  distance: -(sizeW * 2.6),
                  formatter(param) {
                    return `{sty1|${param.data.value[2]}}`;
                  },
                  rich: {
                    sty1: {
                      //  verticalAlign: 'middle',
                      //  align: 'center',
                      color: "#0267CB",
                      fontWeight: 600,
                      fontSize: sizeW * 1,
                    },
                  },
                  textStyle: {
                    color: "#ffffff",
                  },
                },
              });
            }
          });
        }
        return res;
      };
      // console.log(convertData(data));
      // 转化已有数据到地图上显示的配置数据
      //   const convertData = (data)=>{
      //   const res = [];
      //   // eslint-disable-next-line no-plusplus
      //   if (data && data.length > 0) {
      //     for (let i = 0; i < data.length; i++) {
      //       mapData.forEach((v) => {
      //         if (v.name == this.provinceShort(data[i].name)) {
      //           const value = v.cp.concat([0]);
      //           res.push({
      //             name: data[i].name,
      //             value: value,
      //             projectName: data[i].value,
      //             label: {
      //               show: true,
      //               position: 'top',
      //               distance: -5,
      //               formatter(param) {
      //                 return `{sty1|${param.data.projectName}}`;
      //               },
      //               rich: {
      //                 sty1: {
      //                   padding: getSize(0.07),
      //                   backgroundColor: 'rgba(7, 28, 38, 0.7)',
      //                   borderWidth: 1,
      //                   borderColor: '#FF771A',
      //                   borderRadius: 2,
      //                   fontSize: getSize(0.14),
      //                 },
      //               },
      //               textStyle: {
      //                 color: '#ffffff',
      //               },
      //             },
      //             emphasis: {
      //               label: {
      //                 show: true,
      //                 position: 'top',
      //                 distance: 0,
      //                 formatter(param) {
      //                   return `{sty1|${param.data.projectName}}`;
      //                 },
      //                 rich: {
      //                   sty1: {
      //                     padding: 7,
      //                     backgroundColor: 'rgba(7, 28, 38, 0.7)',
      //                     borderWidth: 1,
      //                     borderColor: '#FF771A',
      //                     borderRadius: 2,
      //                     fontSize: getSize(0.14),
      //                   },
      //                 },
      //                 textStyle: {
      //                   color: '#ffffff',
      //                 },
      //               },
      //             },
      //           });
      //         }
      //       });
      //     }
      //   }
      //   return res;
      // }
      const regions = data.map((item) => {
        return {
          name: this.provinceShort(item.name),
          // itemStyle: {
          //   normal: {
          //     areaColor: "#D94E5D",
          //     color: "transparent",
          //   },
          // }
        };
      });
      let sizeW = getSize(0.3);
      // let sizeW = 12;
      return {
        // tooltip: {
        //   trigger: 'item',
        //   formatter: '{b}<br/>{c}'
        // },
        geo: [
          {
            map: this.mapName,
            roam: false, //不开启缩放和平移
            zoom: this.mapName === "china" ? 1.25 : 1, //视角缩放比例
            bottom: 10,
            // itemStyle: {
            //   normal: {
            //     areaColor: "#073B7E",
            //     borderColor: "#0cabde" // 省市边界线
            //   },
            //   emphasis: {
            //     show: false,
            //     disabled: true,
            //     areaColor: '#9DCCFF',//鼠标滑过区域颜色
            //     label: {
            //       show: true,
            //       textStyle: {
            //         color: '#fff'
            //       }
            //     }
            //   }
            // },
            // aspectScale: 0.9,
            roam: false, //是否允许缩放
            layoutSize: "95%",
            layoutCenter: ["50%", "50%"],
            label: {
              normal: {
                show: false, //地区名称
                fontSize: sizeW * 0.9,
                color: "#ffffff",
              },
              emphasis: {
                textStyle: {
                  color: "rgba(243, 248, 254, 1)",
                },
              },
            },
            itemStyle: {
              // areaColor: "#16328E",
              borderColor: "rgba(88, 155, 237, 1)", // 省市边界线
              // borderWidth: 2,,
              color: {
                image: this.imgUrl,
                repeat: "repeat",
              },
              emphasis: {
                color: "#003dea", //移入后的颜色
              },
            },
            emphasis: {
              itemStyle: {
                color: {
                  image: this.imgUrl,
                  repeat: "repeat",
                },
              },
              label: {
                show: 0,
                color: "#fff",
              },
            },
            zlevel: 3,
            regions: regions,
          },
        ],
        series: [
          // {
          //   type: 'scatter',
          //   coordinateSystem: 'geo',
          //   data: convertData(data),
          //   symbolSize: 5,
          //   label: {
          //     normal: {
          //       formatter: '{b}',
          //       position: 'right',
          //       show: false
          //     },
          //   },
          //   itemStyle: {
          //     normal: {
          //       color: '#ddb926'
          //     },
          //   }
          // },
          {
            name: "点",
            type: "scatter",
            coordinateSystem: "geo",
            legendHoverLink: false,
            label: {
              //   normal: {
              //     show: true,
              //     formatter: function (obj) {
              //       return obj.data.value[2];
              //     },
              //     textStyle: {
              //       color: '#000000'
              //     }
              //   }
            },
            symbol: function (params) {
              //   return symbolImg[params[2].toString().length - 1];
              // return 'image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/mapkhName.png'
              return "image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/equipment-map.png";
            },
            symbolSize: function (params) {
              //   let size = [sizeW * 3.5, sizeW * 3.5];
              let size = 0;
              switch (params[2].toString().length) {
                case 1:
                  size = [sizeW * 3.5, sizeW * 3.5];
                  //size = sizeW * 3.5;
                  break;
                case 2:
                  size = [sizeW * 3.7, sizeW * 3.7];
                  //size = sizeW * 3.7;
                  break;
                case 3:
                  size = [sizeW * 4.5, sizeW * 4.5];
                  //size = sizeW * 4.5;
                  break;
                case 4:
                  size = [sizeW * 4.5, sizeW * 4.5];
                  //size = sizeW * 4.5;
                  break;
                case 5:
                  size = [sizeW * 5.8, sizeW * 5.8];
                  //size = sizeW * 5.8;
                  break;
                default:
                  size = [sizeW * 5.8, sizeW * 5.8];
                  //size = sizeW * 5.8;
                  break;
              }
              return size;
            },
            symbolOffset: [sizeW * -0.1, sizeW * -0.1],
            // ../../assets/screen/position-map.png
            itemStyle: {
              normal: {
                borderColor: "#EDF4FD",
                color: "#4BE1FF", // 标志颜色
              },
            },
            emphasis: {
              scale: false,
            },
            selectedMode: false,
            zlevel: 6,
            data: convertData(data),
          },
        ],
      };
    },

    clickTooltip(item) {
      return !item.disabled;
    },

    // 获取icon Name
    vehicleIconName(item) {
      console.log(item);
      return vehiclePicture(item.dictType, item.dictValue);
    },

    // 获取当前点击的省份数据分布
    async mapClick(data = "") {
      // this.isMapLoading = true;
      if (this.mapName !== "china") {
        return false;
      }
      const { name } = data;
      // this.$emit('mapChang', data)
      this.handleDealWithMap(data);
      // getAreaVehicleCount({
      //    area: name
      // }).then(res=>{
      //    console.log(res);
      // })
    },
    async handleDealWithMap(data) {
      if (this.mapName === "china") {
        let initData = await filterMapData(this.mapListData, "", true);
        const pushData = {
          mapListData: JSON.parse(JSON.stringify(this.mapListData)),
          adcode: this.mapAdcode,
          mapName: this.mapName,
        };
        this.mapHierarchyList.push({
          mapListData: JSON.parse(JSON.stringify(this.mapListData)),
          adcode: this.mapAdcode,
          mapName: this.mapName,
        });
        const filData = initData.filter(
          (val) =>
            val.name.indexOf(data.name) > -1 || data.name.indexOf(val.name) > -1
        )[0];
        let ddd = await this.downloadedMaps(filData.adcode, filData.name);
        if (ddd) {
          this.mapName = filData.name;
          this.mapAdcode = filData.adcode;
          this.mapHierarchyList.push(pushData);
          //  this.mapOption = ddd.filter(val => val.value && val.value > 0);
          this.mapOption = this.getMapOption(
            ddd.filter((val) => val.value && val.value > 0)
          );
        } else {
          this.$refs.ModalMap.isMapLoading = false;
        }
        this.mapHierarchyList.push(pushData);
        // this.mapOption = ddd.filter(val=> val.value && val.value > 0)
      } else {
        const pushData = {
          mapListData: JSON.parse(JSON.stringify(this.mapListData)),
          adcode: this.mapAdcode,
          mapName: this.mapName,
        };
        const filData = this.mapListData.filter(
          (val) =>
            val.name.indexOf(data.name) > -1 || data.name.indexOf(val.name) > -1
        )[0];
        let ddd = await this.downloadedMaps(filData.adcode, filData.name);
        if (ddd) {
          this.mapName = filData.name;
          this.mapAdcode = filData.adcode;
          this.mapHierarchyList.push(pushData);
          //  this.mapOption = ddd.filter(val => val.value && val.value > 0);
          this.mapOption = this.getMapOption(
            ddd.filter((val) => val.value && val.value > 0)
          );
        } else {
          this.$refs.ModalMap.isMapLoading = false;
        }
      }
    },
    async downloadedMaps(adcode = "100000", name) {
      let mapUrl = `https://geo.datav.aliyun.com/areas_v3/bound/${adcode}_full.json`;
      let resultData = await getLatLon({ url: mapUrl });
      if (!resultData.obj) {
        this.backLoading = false;
        //   this.$refs.ModalMap.isMapLoading = false;
        this.$message.error("暂不支持该区域缩放");
        return false;
      }
      const specialList = [
        "北京",
        "上海",
        "重庆",
        "天津",
        "北京市",
        "上海市",
        "重庆市",
        "天津市",
      ];
      //抽取数据
      let dataList = (this.data = await filterAliMapData(
        resultData,
        this.provinceName
          ? this.provinceName + "," + name
          : specialList.includes(name)
          ? name + ",市辖区"
          : name,
        null,
        "getAreaVehicleCount"
      ));
      if (
        name.indexOf("省") > -1 ||
        name.indexOf("自治区") > -1 ||
        name.indexOf("特别") > -1
      ) {
        this.provinceName = name;
      }
      this.mapListData = JSON.parse(JSON.stringify(dataList));
      echarts.registerMap(name, resultData.obj);
      // this.mapObject = resultData.obj;
      return dataList;
    },
    async mapBackToUp() {
      const data = this.mapHierarchyList.pop();
      this.mapName = data.mapName;
      this.mapListData = data.mapListData;
      if (data.mapName === "china") {
        this.provinceName = "";
        let ddd = await filterAliMapData(
          data.mapListData,
          "",
          null,
          "getAreaVehicleCount"
        );
        //   this.mapOption = dataList.filter(val => val.value && val.value > 0);
        this.mapOption = this.getMapOption(
          ddd.filter((val) => val.value && val.value > 0)
        );
      } else {
        let ddd = await filterAliMapData(
          data.mapListData,
          data.mapName,
          null,
          "getAreaVehicleCount"
        );
        //   this.mapOption = ddd.filter(val => val.value && val.value > 0);
        this.mapOption = this.getMapOption(
          ddd.filter((val) => val.value && val.value > 0)
        );
      }
      this.isMapLoading = false;
    },

    handleGetPercent(a, b = "") {
      return GetPercent(
        a,
        b ||
          Number(
            this.options.overview && this.options.overview.allTotal
              ? this.options.overview.allTotal
              : 0
          ) ||
          0,
        true
      );
    },
    handleNavi(path) {
      this.$router.push({
        path,
      });
    },
  },
  computed: {
    isZgsy() {
      return (
        this.$store.state.userRouter?.children?.find(
          (r) => r.code === "zgsy"
        ) && this.$store.state.userInfo.name != "广东一立科技"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.chartpi {
  height: 20px;
  width: auto;
}
.topTitle {
  // z-index: 9;
  // position: absolute;
  height: 14.3%;
  width: 100%;
  .carTotal-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    > div {
      width: 58px;
      display: flex;
      flex-direction: column;
      color: #ffffff;
      font-size: 0.14rem;
      text-align: center;
      height: 100%;
      > div {
        position: relative;
        flex: 1;
        > img {
          position: absolute;
          height: 100%;
          right: 50%;
          transform: translateX(50%);
          top: 0;
        }
        > div {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 0.11rem;
        }
      }
    }
  }
}
.back {
  position: absolute;
  right: 25px;
  top: 10%;
  width: 32px;
  height: 32px;
  background: rgba(0, 138, 255, 0.16);
  border: 1px solid #008aff;
  background-image: url("../../../../assets/screen/back.png");
  background-repeat: no-repeat;
  background-position: center;
}
.ModalMap {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  .identification-box {
    position: absolute;
    top: 25px;
    left: 20px;
    display: flex;
    align-items: center;
    > div {
      width: 0.92rem;
      height: 0.36rem;
      line-height: 0.36rem;
      text-align: center;
      border-radius: 0.18rem;
      font-size: 0.14rem;
      color: #ffffff;
      margin-right: 0.1rem;
      cursor: pointer;
      background-image: url("../../../../assets/images/bigScreen/naviBotton.png");
      background-size: 100% 100%;
    }
    > div:hover {
      background-image: url("../../../../assets/images/bigScreen/yrBotton.png");
    }
  }
  .statistics-big-box {
    position: absolute;
    bottom: 25px;
    left: 20px;
    display: flex;
    align-items: center;
  }
  .statistics-box {
    // position: absolute;
    // bottom: 25px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    // width: 2.68rem;
    height: 0.5rem;
    font-size: 0.16rem;
    color: #fff;
    padding: 0 0.4rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    &-num {
      font-family: "ZhanKuQingKeHuangYouTi";
      font-size: 0.25rem;
    }
  }
  .statistics-box-bg1 {
    background-image: url(../../../../assets/images/bigScreen/equipment/map-text-bg1.png);
  }
  .statistics-box-bg2 {
    background-image: url(../../../../assets/images/bigScreen/equipment/map-text-bg2.png);
  }
  .statistics-box-bg3 {
    background-image: url(../../../../assets/images/bigScreen/equipment/map-text-bg3.png);
  }
}
.info-content {
  width: 20%;
}
.el-empty {
  margin: 20px 0;
}
.top-tow {
  width: 100%;
  height: 88%;
  overflow: auto;
  color: #ffffff;
  line-height: 30px;
  .item {
    display: flex;
    flex-flow: row;
    img {
      height: 20px;
      margin: 8px 5px;
    }
    label {
      width: 52%;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 5px;
    }
  }
}
::v-deep .el-loading-text {
  color: #19bafd;
  font-size: 16px;
  font-weight: bold;
}
::v-deep .el-icon-loading {
  color: #19bafd;
  font-size: 26px;
  font-weight: bold;
}
</style>
