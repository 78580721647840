<template>
  <div class="mechanical-box">
    <header-name title="机械类型" />
    <div class="mechanical-charts"
      ref="service-bar">
      <Chart style="height:100%;"
        ref="chartBox"
        :option="pieOptions"
        @onReady="handlePieReady"></Chart>

      <div class="mechanical-charts-num" v-if="mechanicalList[pieIndex] && mechanicalList[pieIndex].value">
        <div class="mechanical-charts-num-head">{{ mechanicalList[pieIndex] ? mechanicalList[pieIndex].label : '' }}</div>
        <div class="mechanical-charts-num-ratio">{{ mechanicalList[pieIndex] ? mechanicalList[pieIndex].ratio : '' }}%</div>
        <div class="mechanical-charts-num-proportion">{{ mechanicalList[pieIndex] ? mechanicalList[pieIndex].value : '' }}<span class="mechanical-charts-unit">台</span></div>
      </div>
    </div>

  </div>
</template>

<script>
import HeaderName from '@/views/BigScreenDisplay/components/HeaderName';
import Chart from "@/components/Chart/Charts";
export default {
  components: { HeaderName, Chart },
  props: {
   options: {
      type: Object,
      default: ()=>{}
   }
  },
  data() {
    return {
      pieOptions: {},
      mechanicalList: [],
      pieIndex: 0,
      pieTime: null,
      pieChart: null,
    };
  },
  created() {
  },
  watch: {
   options: {
      handler(v) {
         this.init();
      }
   }
  },
  methods: {
    init() {
      this.$nextTick(() => {
         this.mechanicalList = this.options.vehicleCategoryCount;
        this.initCharts(this.options.vehicleCategoryCount);
      });
    },
    getSize(res, type) {
      const clientWidth = this.$refs['service-bar'].offsetWidth;
      const clientHeight = this.$refs['service-bar'].offsetHeight;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
    initCharts(data) {
      var scale = this.getSize(0.3, 2);
      var echartData = data.map(val=>({
         name: val.label,
         value: val.value,
         ratio: val.ratio
      }));
      var rich = {
        yellow: {
          color: "#ffc72b",
          fontSize: scale * 2.6,
          fontFamily: 'ZhanKuQingKeHuangYouTi',
          align: 'center'
        },
        total: {
          color: "#ffc72b",
          fontSize: scale * 2.2,
          align: 'center'
        },
        white: {
          color: "#fff",
          align: 'center',
          fontSize: scale * 2.2,
          padding: [1, 0]
        },
        blue: {
          color: '#49dff0',
          fontSize: 2.2 * scale,
          align: 'center'
        },
        hr: {
         //  borderColor: '#0b5263',
          width: '100%',
          borderWidth: 1,
          height: 0,
        }
      };
      this.pieOptions = {
        series: [{
          name: '',
          type: 'pie',
          radius: ['45%', '66%'],
          position: 'centen',
          color: ['rgba(255, 242, 125, 1)', 'rgba(183, 231, 122, 1)', 'rgba(255, 187, 51, 1)', 'rgba(248, 121, 121, 1)', '#6f81da', '#00ffb4'],
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'outside',
                color: '#ddd',
                formatter: function (params, ticket, callback) {
                  return '{white|' + params.name + '}\n{hr|}\n{yellow|' + params.value + '/' + params.data.ratio + '% }';
                },
                rich: rich
              },
              labelLine: {
                show: true,
                type: 'dashed',
                length: scale * 2,
                length2: scale * 3,
                color: '#00ffff'
              }
            }
          },
          data: echartData
        }]
      };
    },

    handlePieReady(myChart) {
      if (this.pieTime) {
        clearInterval(this.pieTime); //销毁定时器
        this.pieTime = null;
      }
      let index = 0;//默认选中第一个
      const that = this;
      this.pieIndex = 0;
      this.pieChart = myChart;
      that.setPieRotate();
      myChart.on('mouseover', function (e) {
        if (e.dataIndex != index) {
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: that.pieIndex,
          });
          that.pieIndex = e.dataIndex;
        }
        clearInterval(that.pieTime);
      });
      myChart.on('mouseout', function (e) {
        index = e.dataIndex;
        that.pieIndex = e.dataIndex;
        that.pieTime ? clearInterval(that.pieTime) : '';
        that.setPieRotate();
      });
    },
    setPieRotate() {
      this.pieChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: this.pieIndex,//默认选中第一个
      });
      this.pieTime = setInterval(() => {
        this.pieChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: this.pieIndex,
        });
        this.pieIndex >= this.mechanicalList.length - 1 ? this.pieIndex = 0 : this.pieIndex += 1;
        this.pieChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.pieIndex,//默认选中第一个
        });
      }, 5000);
    }
  },
  destroyed() {
    clearInterval(this.pieTime); //销毁定时器
    this.pieTime = null;
  },
  deactivated() {
    clearInterval(this.pieTime); //销毁定时器
    this.pieTime = null;
  },
}
</script>

<style lang="scss" scoped>
.mechanical-box {
  padding: 3%;
  height: 89.2%;
  max-height: 89.2%;
  display: flex;
  flex-direction: column;
}
.mechanical-charts {
  flex: 1;
  position: relative;
  &-num {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.2rem;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    &-head {
      font-size: 0.16rem;
      color: #FFFFFF;
      margin-bottom: 0.06rem;
    }
    &-ratio {
      font-family: 'ZhanKuQingKeHuangYouTi';
      font-size: 0.18rem;
      color: rgba(255, 253, 55, 1);
    }
    &-proportion {
      font-size: 0.2rem;
      color: #fff;
      font-weight: 400;
      margin-top: 0.06rem;
    }
  }
  &-unit {
    font-size: 0.12rem;
    font-weight: 400;
    color: #ffffff;
    margin-left: 2px;
  }
}
</style>