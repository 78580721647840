<template>
  <div class="screen-user">
    <Chart style="height:100%;min-height: 100px"
      ref="chartBox"
      :option="lineOptions"></Chart>
      <!-- <div class="quantity-box">
         <div :class="{ 'quantity-box-bg': item != ',' && item != '.' }" v-for="item,index in quantityNum" :key="index">
            <span>{{ item }}</span>
         </div>
      </div> -->
      <div class="line-label">
         <div class="line-label-view" v-for="item,index in typeList" :key="index">
            <div class="line-label-view-circle" :class="['line-label-view-circle'+index]"></div>
            <div class="line-label-view-text">{{ item }}</div>
         </div>
      </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
// import { getOilAndTimeTrend } from "@/api/BigScreenDisplay";
export default {
  components: { Chart },
  data() {
    return {
      lineOptions: {},
      typeList: ['工时','油耗'],
    };
  },
  props: {
   options: {
      type: Object,
      default: ()=>{}
   }
  },
  created() {
   //  this.init();
  },
  watch: {
   options: {
      handler(v) {
         this.init();
      }
   }
  },
  methods: {
   init() {
      // getOilAndTimeTrend().then(res=>{
      //    // this.quantityNum = interval(res.obj.totalUser || 0);
         this.$nextTick(() => {
            this.initChats(this.options.oilAndTimeTrend);
         });
      // })
   },
    initChats(dataList) {
      let xAxisData = dataList.map(val=> val.day);
      const series = [
        {
         name: '工时',
          "data": dataList.map(val=> val.todayTotalTime),
          "type": "line",
          "smooth": true,
          "symbol": "none",
          "color": {
            "x": 0,
            "y": 0,
            "x2": 0,
            "y2": 1,
            "type": "linear",
            "global": false,
            "colorStops": [
              {
                "offset": 0,
                "color": "rgba(150, 245, 248, 1)"
              }
            ]
          },
          yAxisIndex :0,
        },
        {
         name: '油耗',
          "data": dataList.map(val=> val.todayTotalOil),
          "type": "line",
          "smooth": true,
          symbol: "none",
          "itemStyle": {
            color: "#FFFD37",
            borderWidth: 1
          },
          "lineStyle": {
            width: 1
          },yAxisIndex :1,
        }
      ];
      // 数图配置项
      this.lineOptions = {
        tooltip: {
          trigger: "axis",
         //  formatter: function (params, ticket, callback) {
         //    // console.log(params)
         //    let str = `${ params[0].axisValue }</br>`;
         //    params.forEach((item, idx) => {
         //      str += idx === params.length - 1 ? "" : "<br/>";
         //    });
         //    return str;
         //  }
        },
        grid: {
          top: "16%", // 等价于 y: '16%'
          left: "3%",
          right: "3%",
          bottom: "5%",
          containLabel: true
        },
        legend: {
          itemGap: 100,
          data: [
            'Upload',
            'Download'
          ],
          textStyle: {
            //图例文字的样式
            color: "#fff"
          },
          left: "center",
          top: 10
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xAxisData,
          axisLine: {
            lineStyle: {
              color: "#575E71"
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff"
            }
          }
        },

        yAxis: [{
          type: "value",
          position: 'left',
          axisLine: {
            lineStyle: {
              color: "#575E71"
            }
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "rgba(13, 151, 235, .3)"
            }
          },
          axisLabel: {
            formatter: function (value) {
              return value +'H';
            },
            textStyle: {
              color: "#fff"
            }
          }
        },
        {
          type: "value",
          position: 'right',
          axisLine: {
            lineStyle: {
              color: "#575E71"
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: "#0D97EB"
            }
          },
          axisLabel: {
            formatter: function (value) {
              return value+'L';
            },
            textStyle: {
              color: "#fff"
            }
          }
        }],
        series: series
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.screen-user {
   position: relative;
   height: 89%;
   .quantity-box {
      position: absolute;
      top: -9.5%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      div {
         span {
            font-size: 0.48rem;
          font-family: 'ZhanKuQingKeHuangYouTi';
          background-image: -webkit-linear-gradient(
            bottom,
            rgba(22, 142, 245, 1),
            rgba(255, 255, 255, 1)
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
         }
      }
      &-bg {
         padding: 0 0.16rem;
         background-image: url('../../../../assets/images/bigScreen/home/quantity.png');
         background-size: 100% 100%;
      }
   }

   .line-label{
      position: absolute;
      right: 20px;
      top: 0;
      display: flex;
    justify-content: space-around;
    &-view {
      display: flex;
      align-items: center;
      margin-left: 10px;
      &-circle {
        width: 0.14rem;
        height: 0.14rem;
        margin-right: 8px;
        border-radius: 50%;
        background-color: rgba(150, 245, 248, 1);
      }
      &-text {
        font-size: 0.16rem;
        color: #ffffff;
      }
      &-circle1 {
        background-color: rgba(255, 253, 55, 1);
      }
    }}
}
</style>