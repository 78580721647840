<template>
  <div class="fuel-oil">
    <div class="fuel-oil-current"
      ref="current-box">
      <div style="height:100%"><img src="../../../../assets/images/bigScreen/equipment/fuel-oil-icon.png"
          :style="{ width: pieW +'px',marginRight: pieW / 4 +'px' }"
          alt=""
          srcset=""></div>
      <div class="fuel-oil-current-text">
        <div class="fuel-oil-current-text-content">
         <div style="height:3%"></div>
          <div class="fuel-oil-current-text-content">今日油耗量累计</div>
         <div style="height:10%"></div>
          <div class="fuel-oil-current-text-content2">
            较昨日
            <span style="margin-left:4px" :style="{color: (options.oilAndTime && options.oilAndTime.todayTotalOil?options.oilAndTime.todayTotalOil:0) > (options.oilAndTime && options.oilAndTime.yesterdayTotalOil?options.oilAndTime.yesterdayTotalOil:0) ? 'rgba(13, 239, 2, 1)' : 'red'}">{{ ((options.oilAndTime && options.oilAndTime.todayTotalOil?options.oilAndTime.todayTotalOil:0) - (options.oilAndTime && options.oilAndTime.yesterdayTotalOil?options.oilAndTime.yesterdayTotalOil:0)).toFixed(2) }}</span>
            <img src="../../../../assets/images/bigScreen/home/up.png" v-if="(options.oilAndTime && options.oilAndTime.todayTotalOil?options.oilAndTime.todayTotalOil:0) > (options.oilAndTime && options.oilAndTime.yesterdayTotalOil?options.oilAndTime.yesterdayTotalOil:0)"
                alt=""
                srcset="">
            <img v-else src="../../../../assets/images/bigScreen/home/decline.png"
                alt=""
                srcset="">
         </div>
        </div>
        <div class="fuel-oil-current-num">{{ (options.oilAndTime && options.oilAndTime.todayTotalOil?options.oilAndTime.todayTotalOil:0) }}</div>
      </div>
    </div>
    <div class="fuel-oil-type">
      <div class="fuel-oil-type-left">
         作业<span class="fuel-oil-type-num">{{ (options.oilAndTime && options.oilAndTime.todayWorkTotalOil?options.oilAndTime.todayWorkTotalOil:0) }}</span>
      </div>
      <div class="fuel-oil-type-right">怠速<span class="fuel-oil-compare-num">{{ (options.oilAndTime && options.oilAndTime.todayIdleTotalOil?options.oilAndTime.todayIdleTotalOil:0) }}</span></div>
    </div>
    <div class="fuel-oil-compare">
      <div>
         昨日燃油：<span class="fuel-oil-compare-num">{{ (options.oilAndTime && options.oilAndTime.yesterdayTotalOil?options.oilAndTime.yesterdayTotalOil:0) }}</span>
      </div>
      <div>本月日均：<span class="fuel-oil-compare-num">{{ (options.oilAndTime && options.oilAndTime.currentMonthAvgDayTotalOil?options.oilAndTime.currentMonthAvgDayTotalOil:0) }}</span></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pieW: 0
    };
  },
  props: {
   options: {
      type: Object,
      default: ()=>{}
   }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.pieW = this.$refs['current-box'].offsetHeight;
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.fuel-oil {
  height: 90%;
  padding: 5%;
  &-current {
    display: flex;
    height: 40%;
    margin-top: 1%;
    &-text {
      flex: 1;
      display: flex;
      >div {
         flex: 1;
      }
      &-content {
        font-size: 0.15rem;
        font-weight: 300;
        color: #ffffff;
      }
      &-content2 {
         display: flex;
         align-items: flex-end;
        font-size: 0.13rem;
        font-weight: 300;
        color: #ffffff;
        img {
         width: 0.16rem;
        }
      }
    }
    &-num {
      font-family: 'ZhanKuQingKeHuangYouTi';
      font-size: 0.25rem;
      color: rgba(255, 253, 55, 1);
    }
  }
  &-compare {
   display: flex;
   align-items: center;
   height: 30%;
   >div {
   display: flex;
   align-items: center;
   justify-content: center;
      width: 46%;
      height: 100%;
      text-align: center;
      font-size: 0.15rem;
      font-weight: 300;
      color: #FFFFFF;
      background-image: url('../../../../assets/images/bigScreen/equipment/man-hour-model.png');
      background-size: 100% 100%;
   }
   &-num {
      font-family: 'ZhanKuQingKeHuangYouTi';
      font-size: 0.25rem;
      color: rgba(255, 253, 55, 1);
      margin-left: 6%;
   }
  }
  &-type {
   display: flex;
   align-items: center;
   height: 25%;
   margin-top: 1%;
   >div {
   display: flex;
   align-items: center;
      width: 46%;
      height: 100%;
      font-size: 0.15rem;
      font-weight: 300;
      color: #FFFFFF;
      padding-left: 12%;
   }
   &-num {
      font-family: 'ZhanKuQingKeHuangYouTi';
      font-size: 0.25rem;
      color: rgba(98, 255, 168, 1);
      margin-left: 6%;
   }
   &-left {
      background-image: url('../../../../assets/images/bigScreen/equipment/fuel-oil-type-left.png');
      background-size: 100% 100%;
   }
   &-right {
      background-image: url('../../../../assets/images/bigScreen/equipment/fuel-oil-type-right.png');
      background-size: 100% 100%;
   }
  }
}
</style>