<template>
  <div class="man-hour-box">
    <div class="man-hour-current">
      <div class="man-hour-current-left" ref="pie-box">
        <img src="../../../../assets/images/bigScreen/equipment/man-hour-icon.png"
          :style="{width: pieW + 'px', height: 'fit-content'}"
          alt=""
          srcset="">
        <div class="man-hour-current-left-content">
          <div class="man-hour-current-left-content-num">{{ options.oilAndTime && options.oilAndTime.todayTotalTime ? options.oilAndTime.todayTotalTime : '0' }}</div>
          <div class="man-hour-current-left-content-text">今日工时累计</div>
          <div class="man-hour-current-left-content-contrast">较昨日
            <span style="margin-left:4px" :style="{color: options.oilAndTime && options.oilAndTime.todayTotalTime > options.oilAndTime.yesterdayTotalTime ? 'rgba(13, 239, 2, 1)' : 'red'}">{{ ((options.oilAndTime && options.oilAndTime.todayTotalTime ?  options.oilAndTime.todayTotalTime : 0) - (options.oilAndTime && options.oilAndTime.yesterdayTotalTime?options.oilAndTime.yesterdayTotalTime:0)).toFixed(2) }}</span>
            <img src="../../../../assets/images/bigScreen/home/up.png" v-if="(options.oilAndTime && options.oilAndTime.todayTotalTime ?  options.oilAndTime.todayTotalTime : 0) > (options.oilAndTime && options.oilAndTime.yesterdayTotalTime?options.oilAndTime.yesterdayTotalTime:0)"
                alt=""
                srcset="">
            <img v-else src="../../../../assets/images/bigScreen/home/decline.png"
                alt=""
                srcset="">
          </div>
        </div>
      </div>
      <div class="man-hour-current-right">
        <Chart style="height:100%;" :style="{width: pieW + 'px'}"
          ref="chartBox"
          :option="pieOptions"></Chart>
        <div class="man-hour-current-right-content">
          <div class="man-hour-current-right-content-text"><span class="man-hour-current-right-content-text-t">工作</span><span>{{ options.oilAndTime && options.oilAndTime.todayWorkTotalTime ? options.oilAndTime.todayWorkTotalTime : 0 }}</span><span>{{ handleGetPercent((options.oilAndTime && options.oilAndTime.todayWorkTotalTime ? options.oilAndTime.todayWorkTotalTime : 0), (options.oilAndTime && options.oilAndTime.todayIdleTotalTime ? options.oilAndTime.todayIdleTotalTime : 0) + (options.oilAndTime && options.oilAndTime.todayWorkTotalTime ? options.oilAndTime.todayWorkTotalTime : 0)) }}%</span></div>
          <div class="man-hour-current-right-content-text"><span class="man-hour-current-right-content-text-t">怠速</span><span>{{ options.oilAndTime && options.oilAndTime.todayIdleTotalTime ? options.oilAndTime.todayIdleTotalTime : 0 }}</span><span>{{ handleGetPercent((options.oilAndTime && options.oilAndTime.todayIdleTotalTime ? options.oilAndTime.todayIdleTotalTime : 0), (options.oilAndTime && options.oilAndTime.todayIdleTotalTime ? options.oilAndTime.todayIdleTotalTime : 0) + (options.oilAndTime && options.oilAndTime.todayWorkTotalTime ? options.oilAndTime.todayWorkTotalTime : 0)) }}%</span></div>
        </div>
      </div>
    </div>
    <div class="man-hour-formerly">
      <div class="man-hour-formerly-view">
         <div class="man-hour-formerly-view-collect">
            <span class="man-hour-formerly-view-collect-text">昨日工时：</span>
            <span class="man-hour-formerly-view-collect-num">{{ (options.oilAndTime && options.oilAndTime.yesterdayTotalTime?options.oilAndTime.yesterdayTotalTime:0) }}</span>
         </div>
         <div class="man-hour-formerly-view-resolution">
            <div>
               <span class="man-hour-formerly-view-resolution-text">工作：</span>
               <span class="man-hour-formerly-view-resolution-num">{{ (options.oilAndTime && options.oilAndTime.yesterdayWorkTotalTime?options.oilAndTime.yesterdayWorkTotalTime:0) }}</span>
            </div>
            <div>
               <span class="man-hour-formerly-view-resolution-text">怠速：</span>
               <span class="man-hour-formerly-view-resolution-num">{{ (options.oilAndTime && options.oilAndTime.yesterdayIdleTotalTime?options.oilAndTime.yesterdayIdleTotalTime:0) }}</span>
            </div>
         </div>
      </div>
      <div class="man-hour-formerly-view">
         <div class="man-hour-formerly-view-collect">
            <span class="man-hour-formerly-view-collect-text">本月日均：</span>
            <span class="man-hour-formerly-view-collect-num">{{ (options.oilAndTime && options.oilAndTime.currentMonthAvgDayTotalTime?options.oilAndTime.currentMonthAvgDayTotalTime:0) }}</span>
         </div>
         <div class="man-hour-formerly-view-resolution">
            <div>
               <span class="man-hour-formerly-view-resolution-text">工作：</span>
               <span class="man-hour-formerly-view-resolution-num">{{ (options.oilAndTime && options.oilAndTime.currentMonthAvgDayWorkTotalTime?options.oilAndTime.currentMonthAvgDayWorkTotalTime:0) }}</span>
            </div>
            <div>
               <span class="man-hour-formerly-view-resolution-text">怠速：</span>
               <span class="man-hour-formerly-view-resolution-num">{{ (options.oilAndTime && options.oilAndTime.currentMonthAvgDayIdleTotalTime?options.oilAndTime.currentMonthAvgDayIdleTotalTime:0) }}</span>
            </div>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
import { GetPercent } from "@/utils/index.js"
export default {
  components: { Chart },
  data() {
    return {
      pieOptions: {},
      pieW: 0
    };
  },
  props: {
   options: {
      type: Object,
      default: ()=>{}
   }
  },
  watch: {
   options: {
      handler(v) {
         this.$nextTick(()=>{
            this.init();
         })
      }
   }
  },
  mounted() {
    this.$nextTick(()=>{
      this.init();
    })
  },
  methods: { 
    init() {
      this.$nextTick(()=>{
         this.pieW ? '' : this.pieW = this.$refs['pie-box'].offsetHeight;
         this.initCharts();
      })
    },
    initCharts() {
      var echartData = [{
        value: (this.options.oilAndTime && this.options.oilAndTime.todayWorkTotalTime ? this.options.oilAndTime.todayWorkTotalTime : 0),
        name: '工作'
      }, {
        value: (this.options.oilAndTime && this.options.oilAndTime.todayIdleTotalTime ? this.options.oilAndTime.todayIdleTotalTime : 0),
        name: '怠速'
      }];
      this.pieOptions = {
        title: {
          text: '占比',
          left: 'center',
          top: '50%',
          padding: [0, 0],
          textStyle: {
            color: '#fff',
            fontSize: 8,
            align: 'center'
          }
        },
        series: [{
          type: 'pie',
          radius: ['75%', '95%'],
          label: {
            show: false
          },
          hoverAnimation: false,
          color: ['#0080FF', '#D4D438', '#49dff0', '#034079', '#6f81da', '#00ffb4'],
          data: echartData
        }]
      };
    },
    handleGetPercent(a,b) {
      return GetPercent(a, b)
    }
  }
}
</script>

<style lang="scss" scoped>
.man-hour-box {
  padding: 3%;
  height: 89.2%;
  .man-hour-current {
    display: flex;
    align-items: center;
    height: 60%;
    > div {
      height: 50%;
      padding: 0 3%;
      flex: 1;
    }
    &-left {
      display: flex;
      align-items: flex-end;
      &-content {
        flex: 1;
        margin-left: 6%;
        &-contrast {
         display: flex;
         align-items: flex-end;
          font-size: 0.12rem;
          font-weight: 300;
          color: #ffffff;
          span {
            color: rgba(13, 239, 2, 1);
          }
          img {
            width: 0.16rem;
          }
        }
        &-text {
          font-size: 0.15rem;
          font-weight: 300;
          color: #ffffff;
          margin: 0.08rem 0;
        }
        &-num {
          font-family: 'ZhanKuQingKeHuangYouTi';
          font-size: 0.25rem;
          color: #fffd37;
        }
      }
    }
    &-right {
      display: flex;
      align-items: flex-end;
      padding: 0 !important; 
      &-content {
        flex: 1;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: space-around;
        &-text {
          width: 100%;
          display: flex;
          font-family: 'ZhanKuQingKeHuangYouTi';
          justify-content: space-around;
          font-size: 0.17rem;
          color: #fffd37;
          &-t {
            font-size: 0.15rem;
            font-family: 'Microsoft YaHei';
            font-weight: 300;
            color: #ffffff;
          }
        }
      }
    }
  }
  .man-hour-formerly {
   display: flex;
   height: 40%;
   &-view {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 4%;
      background-image: url('../../../../assets/images/bigScreen/equipment/man-hour-model.png');
      background-size: 100% 100%;
      &-resolution {
         display: flex;
         justify-content: space-around;
            line-height: 0.17rem;
         &-text {
            font-size: 0.13rem;
            font-weight: 300;
            color: #FFFFFF;
         }
         &-num {
            font-family: 'ZhanKuQingKeHuangYouTi';
            font-size: 0.17rem;
            color: rgba(255, 253, 55, 1);
         }
      }
      &-collect {
         flex: 1;
         display: flex;
         align-items: center;
         &-text {
            font-size: 0.15rem;
            font-weight: 300;
            color: #FFFFFF;
         }
         &-num {
            font-family: 'ZhanKuQingKeHuangYouTi';
            font-size: 0.25rem;
            color: rgba(255, 253, 55, 1);
         }
      }
   }
  }
}
</style>