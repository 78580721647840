<template>
  <div id="BigScreenDisplay" ref="BigScreenDisplay" class="data-center">
    <!-- 布局头部 -->
    <div class="header" :class="{ bg1: header1, bg4: header4 }">
      <!-- :class="{'bg1':header1,'bg1':header2,'bg1':header3}" -->
      <div class="path-left">
        <img
          src="../../assets/images/bigScreen/equipment/goHome.png"
          v-if="!header4"
          @click="handleGoPath('BigScreenDisplay')"
          class="go-home"
          alt=""
          srcset=""
        />
        <img
          src="../../assets/images/bigScreen/equipment/sjzs-header.png"
          style="height: 0.68rem"
          alt=""
          srcset=""
        />
        <!-- @click="handleGoPath('BigScreenDisplay')" -->
      </div>
      <div class="header_text_weather">
        <i class="el-icon-location"></i>
        <span style="margin-left: 0.5em">{{ weatherArea }}</span>
        <img class="header_weather" :src="weatherImg" alt="" />
        <span style="margin-left: 0.5em">{{ weatherText }}</span>
      </div>
      <span style="flex: 2"></span>
      <span class="time-pane">
        {{ dateText }}
        <span style="margin: 0 0.5em">{{ dayNum }}</span>
        {{ timeStr }}
      </span>
      <div class="path-left">
        <img
          src="../../assets/images/bigScreen/equipment/jxjk-header.png"
          style="height: 0.52rem"
          @click="handleGoPath('BigScreenDisplayMechanical')"
          alt=""
          srcset=""
        />
        <img
          src="../../assets/images/bigScreen/equipment/enlarge.png"
          class="go-home"
          style="margin-left: 0.3rem; margin-right: 0"
          @click="handleClick"
          alt=""
          srcset=""
        />
      </div>
      <!-- <Fullscreen el="#BigScreenDisplay"
        activeName="icon-quanping11"
        closeName="icon-tuichuquanping"
        class="full-pane" /> -->
    </div>

    <!-- 布局中间部分 -->
    <div class="MainOuter">
      <div class="main">
        <div class="above">
          <div class="left-box main">
            <div class="left-box jxgl-box">
              <div style="height: 10.8%" class="hold-box"></div>
              <statistics style="height: 89.2%" :options="allData" />
            </div>
            <div class="left-box gsgl-box">
              <div style="height: 10.8%" class="hold-box">
                <span class="hold-box-text">单位：小时</span>
              </div>
              <man-hour :options="allData" />
            </div>
          </div>
          <div class="centre-box map-box">
            <ModalMap
              ref="ModalMap"
              @mapChang="handleMapChang"
              :mapName="mapName"
              @backToUp="mapBackToUp"
              :options="allData"
              :productTypes="productTypes"
            />
          </div>
          <div class="right-box main">
            <div class="left-box jxgl-box">
              <div style="height: 10.8%"></div>
              <mechanical-pie :options="allData" />
            </div>
            <div class="left-box bjlb-box">
              <div style="height: 10.8%"></div>
              <alarm :options="allData" />
            </div>
          </div>
        </div>
        <div class="underneath">
          <div class="left-box rygl-box">
            <div style="height: 11%" class="hold-box">
              <span class="hold-box-text">单位：升</span>
            </div>
            <fuel-oil :options="allData" />
          </div>
          <div class="centre-box gxqs-box">
            <div style="height: 11%"></div>
            <effect :options="allData" />
          </div>
          <div class="right-box yjzltj-box">
            <div style="height: 10.8%"></div>
            <early :options="allData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import ModalPieCharts from "./ModalPieCharts";
// import ModalVideo from "./ModalVideo";
import alarmAnalyze from "./components/alarmAnalyze";
import ModalTable from "./ModalTable";
import ModalSemicircle from "./ModalSemicircle";
import ModalLineCharts from "./ModalLineCharts";
import ModalBarCharts from "./ModalBarCharts";
import Fullscreen from "@/components/Icon/FullScreen";
import ModelBg from "./components/ModelBg";
import alarmVideo from "./components/alarmVideo";
import { weatherInfo, amapGetIp } from "@/api/weather";
import { getWeek, getTimes } from "@/utils/getDate";
import {
  workStatistics,
  oilStatistics,
  oilTotal,
  workTotal,
  largeScreenSecurityAlarm,
  getLatLon,
  largeScreenInterfice,
} from "@/api/getData";
import dayjs from "dayjs";
import {
  filterAliMapData,
  filterMapData,
} from "@/components/CenterMap/mapUtils/index";
import chinaLi from "@/components/CenterMap/mapJson/chinaMap.json";
import equipment from "@/views/BigScreenDisplay/components/home/equipment";
import policy from "@/views/BigScreenDisplay/components/home/policy";
import user from "@/views/BigScreenDisplay/components/home/user";
import serviceModel from "@/views/BigScreenDisplay/components/home/service";

// --------------------------------------------新的模块组件--------------------------------------------
import statistics from "@/views/BigScreenDisplay/components/equipment/statistics";
import manHour from "@/views/BigScreenDisplay/components/equipment/manHour";
import mechanicalPie from "@/views/BigScreenDisplay/components/equipment/mechanicalPie";
import early from "@/views/BigScreenDisplay/components/equipment/early";
import alarm from "@/views/BigScreenDisplay/components/equipment/alarm";
import effect from "@/views/BigScreenDisplay/components/equipment/effect";
import fuelOil from "@/views/BigScreenDisplay/components/equipment/fuelOil";
import ModalMap from "@/views/BigScreenDisplay/components/equipment/ModalMap";
import { getAllDataOnPre } from "@/api/BigScreenDisplay";
import { isElement } from "lodash";
import { mapMutations } from "vuex";

import { getMapCarNumberInfo } from "@/api/Map/mapRequst.js";

import moment from "dayjs";

import {
  getAreaAlarmTop,
  getManHourStatistics,
  vehicleInfoCount,
  getProductClassList,
  getRealizeAlarm,
  getProductByProvince,
  getProductTypes,
} from "@/api/getHome";

export default {
  // `name`与路由保持一致
  name: "BigScreenDisplayEquipment",
  components: {
    ModalPieCharts,
    ModalMap,
    // ModalVideo,
    alarmAnalyze,
    ModalTable,
    ModalSemicircle,
    ModalLineCharts,
    ModalBarCharts,
    Fullscreen,
    ModelBg,
    alarmVideo,
    equipment,
    policy,
    user,
    serviceModel,

    //  -------------------------------模块组件------------------------------------
    statistics,
    manHour,
    mechanicalPie,
    early,
    alarm,
    effect,
    fuelOil,
  },
  data() {
    // this.intervalId = null;
    return {
      header1: true,
      header2: false,
      header4: false,
      header5: false,
      timeStr: "", // 时间
      timer: null, // 时间定时器
      pieOption: {}, //饼图
      mapOption: [], // 地图数据
      productTypes: [], // 种类总数
      roseOption: [], // 车型分类
      // 实时警报表头配置;
      visualData: [
        { label: "车牌", key: "plate" },
        { label: "所属组织", key: "groupName" },
        { label: "报警类型", key: "alarmTypeChn" },
        { label: "时间", key: "alarmTime" },
      ],
      visualDataInfo: {}, //实时警报数据
      visualMediaDataInfo: [],

      loading: false, //加载
      rankOption: [], //区域报警排行榜
      lineOption: [], // 工时统计数据
      timeType: 0, //工时统计查询类型 0：周，1：月，2：年（默认查询周）
      infoCountOption: {
        acticeRate: 0,
      }, //实时统计
      oilOptions: {}, //油耗统计
      workOptions: {}, //工时统计
      provinceName: "", // 当前点击的地图省份 默认不传查全国数据
      weatherText: "", //天气说明
      weatherImg: "", //天气图标
      weatherArea: "", //天气地址
      vehicleTotal: 0,
      fullscreenchange: null,
      visibilitychange: null,
      header3: false,
      getData: {
        //工时参数
        pageNumber: 1,
        pageSize: 10,
        vehicleIds: [],
        startTime: getWeek()[1],
        endTime: getWeek()[2],
        groupIds: [],
        intervalMark: 1,
        deviceVehicleIds: [],
        productVehicleIds: [],
        brandVehicleIds: [],
        flag: 1, // 新增-后端数据缓存标志
      },
      realTimeAlarm: "alarmVideo",
      oilData: {
        vehicleIds: [],
        viewType: "D",
        dateStr: "",
        flag: 5,
        startTime: moment(new Date()).format("YYYY-MM-DD"),
        endTime: moment(new Date()).add(1, "days").format("YYYY-MM-DD"),
        groupIds: [],
        exportExcel: false,
      },
      mapName: "china",
      mapAdcode: "china",
      mapListData: [],
      backLoading: false,
      mapHierarchyList: [],
      intervalId: null,

      // -------------
      dayNum: "",
      dateText: "",
      allData: {},
    };
  },
  created() {
    const { name } = this.$store.state.userInfo;
    this.mapListData = chinaLi;
    if (name == "华北利星行") {
      (this.header1 = false), (this.header2 = true);
    } else if (name == "广东伟信市政") {
      this.header3 = true;
    } else if (
      this.$store.state.userRouter?.children?.find((r) => r.code === "zgsy") &&
      name != "广东一立科技"
    ) {
      //中国石油判断
      this.header4 = true;
    }
    // else if (name == "YILI_TECH") {
    //   this.header5 = true;
    // }
  },
  // mounted
  activated() {
    let that = this;
    this.init();
    this.handleGetWeather();
    var datas = dayjs().day();
    var week = ["日", "一", "二", "三", "四", "五", "六"];
    this.dayNum = "周" + week[datas];
    this.dateText = moment().format("YYYY-MM-DD");
    this.timer = setInterval(() => {
      const today = moment(new Date()).format("HH:mm:ss");
      this.timeStr = `${today}`;
    }, 1000);

    // this.intervalId = setInterval(async () => {
    //   await this.init();
    // }, 1000 * 30);
    this.handlePolling();
    // 监听放大页面
    this.fullscreenchange = true;
    addEventListener("fullscreenchange", this.handleFullscreenchange);
    addEventListener("visibilitychange", this.handleVisibilitychange);
  },
  // destroyed
  deactivated() {
    clearInterval(this.timer); //销毁定时器
    // clearInterval(this.intervalId);
    clearTimeout(this.intervalId);
    this.intervalId = null;
    this.fullscreenchange = null;
    removeEventListener("fullscreenchange", this.handleFullscreenchange);
    removeEventListener("visibilitychange", this.handleVisibilitychange);
  },

  methods: {
    async init() {
      let { obj } = await getAllDataOnPre({
        userId: this.$store.state.userInfo.userId,
        accountName: this.$store.state.userInfo.name,
      });
      this.allData = obj;
    },
    handleFullscreenchange() {
      this.init();
    },
    handleVisibilitychange() {
      if (document.visibilityState == "hidden") {
        clearTimeout(this.intervalId);
        this.intervalId = null;
      } else {
        this.init();
        if (!this.intervalId) {
          this.handlePolling();
        }
      }
    },
    handlePolling() {
      this.intervalId = setTimeout(async () => {
        await this.init();
        // this.$refs.ModalMap.mapChange()
        clearTimeout(this.intervalId);
        if (this.fullscreenchange) {
          this.handlePolling();
        }
      }, 1000 * 60);
    },
    // 获取区域报警排行数据
    async getAreaAlarmList() {
      const result = await getAreaAlarmTop();

      if (!(result.flag && result.obj.length)) return;
      let total = null;
      if (result.extend) {
        total = result.extend.number;
      }
      let numTotal = 0;
      result.obj.map((item) => (numTotal += item.areaAlarmTotal));
      // this.rankOption = result.obj.map(item => ({
      //   value: total ? Math.round(item.areaAlarmTotal / (total / 100)) : Math.round(item.areaAlarmTotal / (numTotal / 100)),
      //   label: item.areaName
      // }));

      // this.rankOption.splice(this.rankOption.length, 0, ...new Array(5 - this.rankOption.length).fill({ value: 0, label: '--' })); // 数组补漏
    },

    //工时统计时间类型change
    modalChange(val) {
      this.timeType = val;
      this.getData.intervalMark = val;
      this.getData.startTime = getTimes(val)[0];
      this.getData.endTime = getTimes(val)[1];
      this.getManHourList();
    },

    // 获取工时统计数据
    async getManHourList(data) {
      if (data) {
        const { vehicleStatisticalList } = data;
        this.lineOption = vehicleStatisticalList;
        return false;
      }
      await workStatistics(this.getData).then((res) => {
        if (res && res.obj) {
          const { vehicleStatisticalList } = res.obj;
          this.lineOption = vehicleStatisticalList;
        }
      });
      // await getManHourStatistics({ mode: this.timeType }).then(res => {
      //   if (!(res.flag && res.obj.length)) return;
      //   this.lineOption = res.obj;
      // });
    },

    //实时统计数据
    async getInfoCount(data = {}) {
      // await vehicleInfoCount().then(res => {
      //   if (!(res.flag && res.obj)) return;

      const { offline, vehicleTotal } = data;
      const rate =
        offline && vehicleTotal
          ? ((Number(offline) || 0) / (Number(vehicleTotal) || 0)) * 100
          : 0;
      this.vehicleTotal = vehicleTotal;
      this.infoCountOption = data;
      this.infoCountOption.acticeRate = rate.toFixed(1);
      // });
    },

    //油耗统计数据
    async getOilTotal(result = {}) {
      // const result = await oilTotal({ flag: 1 });

      // if (!(result && result.flag === 1)) return;

      const { todayOilTop5 } = result;
      this.oilOptions = result;
      this.roseOption = todayOilTop5;
      if (todayOilTop5.length < 5) {
        const pushNum = 5 - todayOilTop5.length;
        for (let index = 0; index < pushNum; index++) {
          this.roseOption.push({
            groupId: "",
            oil: "",
            plate: "",
            vehicleId: "",
          });
        }
        // (5 - todayOilTop5.length).map(()=> {
        //   todayOilTop5.push({
        //   groupId: "",
        //   oil: '',
        //   plate: "",
        //   vehicleId: ''
        //   })
        // })
      }
    },

    //工时统计数据
    async getWorkTotal(data = {}) {
      // const result = await workTotal();
      // if (!(result && result.flag === 1)) return;
      this.workOptions = data;
    },
    //获取油耗占比数据
    async getOilFront(data = {}) {
      // const result = await oilStatistics(this.oilData);
      // if (!(result && result.flag === 1)) return;
      const { sumData } = data;
      this.pieOption = sumData;
    },
    async largeScreenSecurityAlarm(data = {}) {
      this.visualDataInfo = {};
      // const { userId } = this.$store.state.userInfo;
      try {
        //   const result = await largeScreenSecurityAlarm({ userId });
        //   if (!(result && result.flag === 1)) return;
        data ? (this.visualDataInfo = data) : "";
        data && data.alarmCountGroupByAlarmType
          ? (this.rankOption = data.alarmCountGroupByAlarmType.map((item) => {
              return {
                value: item.alarmCount,
                progressValue:
                  data.alarmCount &&
                  data.alarmCount.todayAlarmCount &&
                  item.alarmCount
                    ? Math.round(
                        item.alarmCount /
                          (data.alarmCount.todayAlarmCount / 100)
                      )
                    : 0,
                label: item.alarmTypeName,
              };
            }))
          : "";
      } catch (err) {
        console.error(err);
      }
    },
    //获取实时报警数据
    async getRealizeAlarmList(data = []) {
      this.visualMediaDataInfo = [];
      try {
        //   const result = await getRealizeAlarm();

        // console.log(result);
        //   if (!(result && result.flag === 1)) return;

        data
          ? (this.visualMediaDataInfo = data.map((val) => {
              let isImageUrl = /\.(png|jpe?g|gif|svg)(\?.*)?$/;
              isImageUrl.test(val.imagePath)
                ? ""
                : (val.imagePath = require("../../assets/images/bigScreen/default.jpg"));
              val.alarmTime = val.alarmTime ? val.alarmTime.split(" ")[1] : "";
              return val;
            }))
          : "";
        // console.log(this.rankOption);
        // result.extend && result.extend.listRet ? this.rankOption = result.extend.listRet.map(item => ({
        //   value: Math.round(item.sumResult / (1 / 100)),
        //   label: item.labelName
        // })) : '';
      } catch (err) {
        console.error(err);
      }
    },

    // 获取产品种类分布情况
    async getProductTyps(data = []) {
      // const result = await getProductByProvince({ provinceName: this.provinceName });
      //抽取数据
      if (this.mapName === "china") {
        let dataList = await filterAliMapData(chinaLi, "", data);
        this.mapOption = dataList.filter((val) => val.value && val.value > 0);
      } else {
        const specialList = [
          "北京",
          "上海",
          "重庆",
          "天津",
          "北京市",
          "上海市",
          "重庆市",
          "天津市",
        ];
        let ddd = await filterAliMapData(
          this.mapListData,
          this.provinceName &&
            !(
              this.mapName.indexOf("省") > -1 ||
              this.mapName.indexOf("自治区") > -1 ||
              this.mapName.indexOf("特别") > -1
            )
            ? this.provinceName + "," + this.mapName
            : specialList.includes(this.mapName)
            ? this.mapName + ",市辖区"
            : this.mapName
        );
        this.mapOption = ddd.filter((val) => val.value && val.value > 0);
      }
      // let dataList = await filterAliMapData(chinaLi, '');
      // this.mapOption = dataList.filter(val=> val.value && val.value > 0);
      // console.log('------------------',dataList.filter(val=> val.name && val.name > 0));
      // if (result.flag !== 1) return;
      // const { mapResult, typeResult } = result.obj;
      // this.mapOption = mapResult;
      // this.productTypes = typeResult;
    },

    async handleGetWeather() {
      let cityData = await amapGetIp({
        key: "60f85fa7d1c353b0a2ffb92083e365f0",
      });
      let imgList = [
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain1.png", //小雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain2.png", //大雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain3.png", // 雷雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/cloud.png", //多云
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/snow.png", //雪
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/clear.png", // 晴
      ];
      //  {city: cityData.city}
      weatherInfo({
        key: "60f85fa7d1c353b0a2ffb92083e365f0",
        city: cityData.adcode,
      }).then((res) => {
        let weatherData = res.lives[0];
        // let high = weatherData.high.split(' ')[1];
        // let low = weatherData.low.split(' ')[1];
        this.weatherArea = cityData.city;
        this.weatherText =
          weatherData.temperature + "°" + " " + weatherData.weather;
        if (weatherData.weather.indexOf("雷") > -1) {
          this.weatherImg = imgList[2];
        } else if (weatherData.weather.indexOf("大雨") > -1) {
          this.weatherImg = imgList[1];
        } else if (weatherData.weather.indexOf("雨") > -1) {
          this.weatherImg = imgList[0];
        } else if (weatherData.weather.indexOf("雪") > -1) {
          this.weatherImg = imgList[4];
        } else if (weatherData.weather.indexOf("晴") > -1) {
          this.weatherImg = imgList[5];
        } else if (weatherData.weather.indexOf("云") > -1) {
          this.weatherImg = imgList[3];
        } else {
          this.weatherImg = imgList[3];
        }
      });
    },
    async handleMapChang(data) {
      if (this.backLoading) {
        return false;
      }
      this.backLoading = true;
      if (this.mapName === "china") {
        let initData = await filterMapData(this.mapListData, "", true);
        const pushData = {
          mapListData: JSON.parse(JSON.stringify(this.mapListData)),
          adcode: this.mapAdcode,
          mapName: this.mapName,
        };
        // this.mapHierarchyList.push({
        //   mapListData: JSON.parse(JSON.stringify(this.mapListData)),
        //   adcode: this.mapAdcode,
        //   mapName: this.mapName
        // })
        const filData = initData.filter(
          (val) =>
            val.name.indexOf(data.name) > -1 || data.name.indexOf(val.name) > -1
        )[0];
        let ddd = await this.downloadedMaps(filData.adcode, filData.name);
        if (ddd) {
          this.mapName = filData.name;
          this.mapAdcode = filData.adcode;
          this.mapHierarchyList.push(pushData);
          this.mapOption = ddd.filter((val) => val.value && val.value > 0);
        } else {
          this.$refs.ModalMap.isMapLoading = false;
        }
        // this.mapHierarchyList.push(pushData)
        // this.mapOption = ddd.filter(val=> val.value && val.value > 0)
      } else {
        const pushData = {
          mapListData: JSON.parse(JSON.stringify(this.mapListData)),
          adcode: this.mapAdcode,
          mapName: this.mapName,
        };
        const filData = this.mapListData.filter(
          (val) =>
            val.name.indexOf(data.name) > -1 || data.name.indexOf(val.name) > -1
        )[0];
        let ddd = await this.downloadedMaps(filData.adcode, filData.name);
        if (ddd) {
          this.mapName = filData.name;
          this.mapAdcode = filData.adcode;
          this.mapHierarchyList.push(pushData);
          this.mapOption = ddd.filter((val) => val.value && val.value > 0);
        } else {
          this.$refs.ModalMap.isMapLoading = false;
        }
      }
      this.backLoading = false;
    },

    async downloadedMaps(adcode = "100000", name) {
      let mapUrl = `https://geo.datav.aliyun.com/areas_v3/bound/${adcode}_full.json`;
      let resultData = await getLatLon({ url: mapUrl });
      if (!resultData.obj) {
        this.backLoading = false;
        this.$refs.ModalMap.isMapLoading = false;
        this.$message.error("暂不支持该区域缩放");
        return false;
      }
      const specialList = [
        "北京",
        "上海",
        "重庆",
        "天津",
        "北京市",
        "上海市",
        "重庆市",
        "天津市",
      ];
      //抽取数据
      let dataList = (this.data = await filterAliMapData(
        resultData,
        this.provinceName
          ? this.provinceName + "," + name
          : specialList.includes(name)
          ? name + ",市辖区"
          : name
      ));
      if (
        name.indexOf("省") > -1 ||
        name.indexOf("自治区") > -1 ||
        name.indexOf("特别") > -1
      ) {
        this.provinceName = name;
      }
      this.mapListData = JSON.parse(JSON.stringify(dataList));
      echarts.registerMap(name, resultData.obj);
      // this.mapObject = resultData.obj;
      return dataList;
    },
    async mapBackToUp() {
      const data = this.mapHierarchyList.pop();
      this.mapName = data.mapName;
      this.mapListData = data.mapListData;
      if (data.mapName === "china") {
        this.provinceName = "";
        let dataList = await filterAliMapData(data.mapListData, "");
        this.mapOption = dataList.filter((val) => val.value && val.value > 0);
      } else {
        let ddd = await filterAliMapData(data.mapListData, data.mapName);
        this.mapOption = ddd.filter((val) => val.value && val.value > 0);
      }
    },
    getFullScreenEl() {
      const { el } = this;
      // return (isElement('el')
      //   ? el
      //   : document.querySelector(el)) || document.documentElement;
      return this.$refs["BigScreenDisplay"];
    },
    /**
     * 进入全屏
     */
    requestFullscreen() {
      const fullscreenEl = this.getFullScreenEl();

      fullscreenEl.requestFullscreen();
      this.isFullscreen = true;
    },
    /**
     * 退出全屏
     */
    exitFullscreen() {
      document.exitFullscreen();
      this.isFullscreen = false;
    },
    handleClick() {
      this.SetFullscreen();
      // const isFullscreen = !this.isFullscreen;

      // if (isFullscreen) { // 进入全屏
      //   this.requestFullscreen();
      // } else {
      //   // 退出全屏
      //   this.exitFullscreen();
      // }
    },
    handleGoPath(name) {
      this.$router.push({
        path: "/" + name,
      });
    },
    ...mapMutations(["SetFullscreen"]),
  },
};
</script>

<style lang="scss" scoped>
@import "./equipmentStyle.scss";
.bg1 {
  background-image: url("../../assets/images/bigScreen/home/titleBg.png");
}
.bg2 {
  background-image: url("../../assets/images/bigScreen/top3.png");
}
.bg3 {
  background-image: url("../../assets/images/bigScreen/top2.png");
}
.bg4 {
  background-image: url("../../assets/images/bigScreen/msk-top.png");
}
.pattern-img {
  position: absolute;
  top: 17px;
  cursor: pointer;
  right: 35px;
  width: 17px;
  z-index: 99;
}
.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  .above {
    flex: 2;
    display: flex;
    margin-bottom: 2px;
  }
  .underneath {
    flex: 1;
    display: flex;
  }
  .left-box {
    flex: 1;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .centre-box {
    flex: 2;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .right-box {
    flex: 1;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .sbzx-box {
    background-image: url("../../assets/images/bigScreen/home/sbzx.png");
  }
  .map-box {
    background-image: url("../../assets/images/bigScreen/home/mapBg.png");
  }
  .yhzx-box {
    background-image: url("../../assets/images/bigScreen/home/yhzx.png");
  }
  .bdzx-box {
    background-image: url("../../assets/images/bigScreen/home/bdzx.png");
  }
  .jszx-box {
    padding-top: 30px;
    background-image: url("../../assets/images/bigScreen/home/jszx.png");
    .module-box {
      position: relative;
      height: 100%;
    }
    .jszx-ball {
      position: absolute;
      // background-image: url('../../assets/images/bigScreen/home/jsNum.png');
      width: 20%;
      // height: 5.2em;
      // background-size: 100% 100%;
      .jszx-ball-content {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        &-num {
          width: 100%;
          text-align: center;
          //   margin-top: 6%;
          &-text {
            font-size: 0.12rem;
            color: #fff;
          }
          &-value {
            font-family: "ZhanKuQingKeHuangYouTi";
            font-size: 0.26rem;
            font-weight: bold;
            background-image: -webkit-linear-gradient(
              bottom,
              rgba(250, 255, 103, 1),
              rgba(255, 255, 255, 1)
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        &-text {
          width: 100%;
          text-align: center;
          font-size: 0.12rem;
          color: #fff;
          //   margin-top: 6%;
        }
      }
    }
    .jszx-ball-left {
      left: 7%;
      top: 50%;
      transform: translateY(-50%);
    }
    .jszx-ball-right {
      right: 7%;
      top: 50%;
      transform: translateY(-50%);
    }
    .jszx-ball-top {
      top: 3%;
      left: 50%;
      transform: translateX(-50%);
    }
    .jszx-icon {
      position: absolute;
      width: 60%;
      max-height: 100%;
      bottom: 2%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .fwzx-box {
    background-image: url("../../assets/images/bigScreen/home/fwzx.png");
  }
  .fkzx-box {
    background-image: url("../../assets/images/bigScreen/home/fkzx.png");
  }
}
</style>
